import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { Button } from './Button';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false)

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }

  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className="navbar">
  <div className="navbar-container">
    <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
      <img src="/images/arkiapuminjalogo.png" alt="arkiapuminja" className="w-40" />
    </Link>
    <div className="menu-icon" onClick={handleClick}>
      <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
    </div>
    <ul className={click ? 'nav-menu active' : 'nav-menu'}>

      <li className='nav-item'>
        <a href='#service-section' className='nav-links' onClick={closeMobileMenu}>
          Palvelut
        </a>
      </li>

      <li className='nav-item'>
        <a href='#aboutcontainer' className='nav-links' onClick={closeMobileMenu}>
          Meistä
        </a>
      </li>

      <li className='nav-item'>
        <a href='#contact-bigger-container' className='nav-links-mobile' onClick={closeMobileMenu}>
          Ota yhteyttä
        </a>
      </li>

    </ul>
    {button && (
    <Button
        buttonStyle='btn--outline'
        buttonSize='btn--large'
        scrollTo='contact-bigger-container' // Pass the target section ID
    >
        Ota yhteyttä
    </Button>
)}
  </div>
</nav>

    </>
  );
}

export default Navbar
