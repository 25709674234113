import React from 'react'
import '../../App.css';
import HeroSection from '../HeroSection';
import Services from '../Services';
import About from '../About';
import Contact from '../Contact';


function Home() {
    return (
        <>
            <HeroSection />
            <Services/>
            <About/>
            <Contact/>
        </>
    )
}

export default Home;