import React from 'react';
import './About.css';

function About() {
  return (

<div class="w-full py-20 px-10" id="aboutcontainer">

    <h1 class="text-center text-3xl font-bold mb-8 about-text">Tietoa meistä ja yrityksestä</h1>

    <div class="w-full flex flex-wrap justify-center" >
    
        <div class="w-full lg:w-1/3 max-w-lg p-4">
            <img src="/images/arkiapuminja-esittely.png" alt="arkiapuminja" class="w-full h-auto object-cover mb-4"/>
        </div>


        <div class="w-full md:w-1/2 lg:w-1/3 max-w-lg p-4 lg:py-6 p-0 ">
        <h2 class="mb-3">
                Arkiapu MinJa Oy tarjoaa ikäihmisille apua ja tukea arjen haasteisiin
                asiakaslähtöisesti sekä kiireettömästi. 
                Tavoitteena on ikäihmisten kotona asumisen tukeminen ja turvaaminen. Toimimme arkisin
                Hämeenlinnan (myös Lammi, Tuulos, Hauho, Renko, Kalvola), Hattulan sekä Janakkalan
                alueilla. <br></br><br></br>
                Arkiapu MinJa Oy kuuluu ennakkoperintärekisteriin, joten olet oikeutettu saamaan
                kotitalousvähennyksen (60 % työn osuudesta).
                Yrityksemme on Oma Hämeen yksityisten tukipalvelutuottajien listalla sekä
                palvelutuottajarekisteri Soterissa.
                Yhdessä olemme työskennelleet 10 vuotta ennen yrityksen perustamista ikäihmisten asiakasohjausyksikössä.
                <div class="block md:hidden h-px bg-black my-4"></div>
            </h2>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 max-w-lg p-4 lg:py-6 p-0 ">
        <p class="mb-3">
        <blockquote>
                     <p>Olen lähes koko työurani työskennellyt ikäihmisten parissa laitos- tai
                    avopalveluissa. Työurastani olen toiminut vs. sosiaalityöntekijänä, omaishoidon
                    koordinaattorina ja palveluohjaajana viimeiset 10 vuotta. Minulla on viimeisimpänä tutkintona
                    Sosiaali-ja terveysalan YAMK (sosionomi).</p>
                    <footer>— Minna
                    </footer>
                </blockquote>
                <br></br> <br></br>
                <blockquote>
                     <p>Minulla on kokemusta hoitajana ja sairaanhoitajana toimimisesta niin
                        sairaalassa kuin hoivakodissa. Viimeisimpänä kokemuksena lähes 10 vuotta ikäihmisten
                        palveluohjaajana. Minulla on Sairaanhoitajan (Amk) tutkinto sekä Terveystieteiden Maisterin tutkinto,
                        pääaineena Gerontologia ja kansanterveys.
                          </p>
                    <footer>— Jaana
                    </footer>
                </blockquote>
            </p>
        </div>

       
    </div>
</div>
  );
}

export default About;