import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';

const STYLES = ['btn--primary', 'btn--outline'];
const SIZES = ['btn--medium', 'btn--large'];

export const Button = ({
    children,
    type,
    onClick,
    buttonStyle,
    buttonSize,
    scrollTo // Add a new prop for scrolling
}) => {
    const checkButtonStyle = STYLES.includes(buttonStyle) 
        ? buttonStyle 
        : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) 
        ? buttonSize
        : SIZES[0];

    const handleClick = () => {
        // If scrollTo prop is provided, perform the scroll
        if (scrollTo) {
            const element = document.getElementById(scrollTo);
            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        }
        // Call the onClick function if provided
        if (onClick) onClick();
    };

    return (
        <button className={`btn ${checkButtonStyle} ${checkButtonSize}`} type={type} onClick={handleClick}>
            {children}
        </button>
    );
};

export default Button;
