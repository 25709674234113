import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import Services from './Services';

function HeroSection() {
    return (
        <div className='hero-container'>
            <div className='smaller-container'>
            
            <h1>Ikäihmisille apua ja tukea <br/>
            arjen haasteisiin</h1>

            <div className="hero-btns">
                    <Button
                        className='btns'
                        buttonStyle='btn--outline'
                        buttonSize='btn--large'
                        onClick={() => window.scrollTo({
                            top: document.getElementById('service-section').offsetTop,
                            behavior: 'smooth'
                        })}
                    >
                        PALVELUMME
                    </Button>
                </div>
            </div>

            
        </div>
    )
}

export default HeroSection;
